import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output, SkipSelf, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutocompleteOptions } from '@app/root-store/suggestions';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import { RdsAutocompleteComponent } from '@rds/angular-components';
import { debounceTime, filter, first, map, Observable, tap } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'rh-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: AutocompleteComponent
    }
]
})
export class AutocompleteComponent<T = string> implements OnInit, OnDestroy {
  private subs: SubSink = new SubSink();

  private _autocomplete: RdsAutocompleteComponent<T>;

  get autocomplete() {
    return this._autocomplete
  }
  @ViewChild('autocompleteRef', { read: RdsAutocompleteComponent, static: false}) set autocomplete(a: RdsAutocompleteComponent<T>) {
    if (!!a) {
      this._autocomplete = a;
      (a as any).inputEl.nativeElement.addEventListener('blur', (e) => {
        this.markAsTouched();
      });
    }
  };

  @Input() itemTemplate: TemplateRef<any>;
  @Input() selectedTemplate: TemplateRef<any>;
  
  @Input() uniqueKey: string;
  @Input() displayKey: string;
  @Input() options: Observable<AutocompleteOptions<T>>;
  @Input() preventAdd: Array<string> = [];
  @Input() preventRemove: Array<string> = [];
  @Input() allowNew = false;

  @Input() label: string = 'Select';
  @Input() chipPlaceholder: string = 'Selected option goes here';

  @Input() formControlName!: string;
  @Input() formControl!: FormControl;
  
  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  @Input() required = false;
  @Input() multi = false;

  isStringOptions = false;
  selectedOptions: Array<T> = [];
  touched = false;
  disabled = false;
  
  onChange = (rada) => {};
  onTouched = () => {};
  writeValue(value: Array<T>) { this.selectedOptions = value; this.cdr.markForCheck(); }
  registerOnChange(onChange: any) { this.onChange = onChange; }
  registerOnTouched(onTouched: any) { this.onTouched = onTouched; }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState(disabled: boolean) { this.disabled = disabled; this.cdr.markForCheck();}

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() selected: EventEmitter<T> = new EventEmitter<T>();
  @Output() removed: EventEmitter<T> = new EventEmitter<T>();
  @Output() details: EventEmitter<T> = new EventEmitter<T>();
  
  fetchOptions = (search: string) => {
    this.search.emit(search);
    return this.options.pipe(
      filter(({options, phrase, loading, isString}) => phrase === search && loading === false),
      map(({options}) => options.filter((o) => !this.isSelected(o)))
    )
  }

  isSelected = (option: T) => {
    return this.selectedOptions.findIndex(o => this.isStringOptions ? (o as string).trim().toLowerCase() === (option as string).trim().toLowerCase() : o[this.uniqueKey] === option[this.uniqueKey]) > -1;
  }

  isPreventedFromAdding = (option: T) => {
    return this.preventAdd.findIndex(p => this.isStringOptions ? p.trim().toLowerCase() === (option as string).trim().toLowerCase() : p.trim().toLowerCase() === option[this.uniqueKey].toLowerCase()) > -1;
  }

  isPreventedFromRemoving = (option: T) => {
    return this.preventRemove.findIndex(p => this.isStringOptions ? p.trim().toLowerCase() === (option as string).trim().toLowerCase() : p.trim().toLowerCase() === option[this.uniqueKey].toLowerCase()) > -1;
  }

  blur() {
    this.markAsTouched();
  }

  enter(phrase: string) {
    if (this.allowNew && this.isStringOptions && phrase.trim().length > 0) {
      if (this.selectedOptions.findIndex(o => (o as string).trim().toLowerCase() === phrase.trim().toLowerCase()) === -1) {
        this.select((phrase as T));
      } else {
        this.autocomplete.clear();
      }
    }
    setTimeout(() => {
      (this.autocomplete as any).focusChanged(false);
      (this.autocomplete as any).focusChanged(true);
      this.autocomplete.hide();
    }, 100);
  }

  select(option: T) {
    let opt = option;
    if (typeof opt === 'string') {
      (opt as string) = opt.trim();
    }
    this.multi ? this.selectedOptions = [...this.selectedOptions, opt] : this.selectedOptions = [opt];
    this.autocomplete.clear();
    this.selected.emit();
    this.onChange(this.selectedOptions.slice())
  }

  remove(index: number) {
    this.selectedOptions = this.selectedOptions.filter((g, i) => i !== index);
    this.removed.emit();
    this.onChange(this.selectedOptions.slice())
  }

  public form: FormGroup = new FormGroup({ item: new FormControl('') }, {updateOn: 'change'});

  ngOnInit(): void {
    this.form.controls.item.setValidators(CustomValidators.isMasterControlValid(this.control));
    this.form.controls.item.updateValueAndValidity();

    const originalMarkAsTouched = this.control.markAsTouched;
    const that = this;
    this.control.markAsTouched = function () {
        originalMarkAsTouched.apply(this, arguments);
        that.form.controls.item.markAsTouched();
        that.form.controls.item.updateValueAndValidity();
    }

    this.subs.sink = this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
        this.form.controls.item.updateValueAndValidity();
    });

    this.subs.sink = this.options.pipe(first()).subscribe(({isString}) => {
      this.isStringOptions = isString
    });

    this.subs.sink = this.form.controls.item.valueChanges
      .pipe(
        filter(item => !!item && (!this.uniqueKey || this.uniqueKey && item[this.uniqueKey])) )
      .subscribe(item => this.select(item))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  constructor( 
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef ) {}
  
}
