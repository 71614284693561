import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {DatePipe, Location} from '@angular/common';
import {ApiService, HttpError} from '@app/core/services/api/api.service';
import {EndpointsService} from '@app/core/services/endpoints/endpoints.service';
import {RhRecipientsListForm, RhRecipientsListRequestBody} from '@app/newsletter-new/models/recipients-list';
import {Block, BlockTypes} from '@app/newsletter/inline-editor/models/block-type.enum';
import {Section, Subsection} from '@app/newsletter/inline-editor/models/section-type.enum';
import {Section as NewSection, Subsection as NewSubsection} from '@app/newsletter-new/models/section-type.enum';
import {
  ChannelNewsletterModel,
  ColorsPalette,
  MySavedTemplatesModel,
  MySavedTemplatesResponse,
  NewsDynamicLink,
  NewsletterInlineForm,
  NewsletterListItemModel,
  NewsletterListModel,
  NewsletterLoadedValueModel,
  NewsletterSectionsModel,
  NewsletterTableModel,
  NewsletterValueModel,
  StatisticItemModel,
} from '@app/newsletter/models';
import {DraftModel} from '@app/newsletter/models/draft.model';
import {TemplateModel} from '@app/newsletter/models/template.model';
import {SortEnum} from '@core/enums/sort.enum';
import {Sort, SortDirection} from '@rds/angular-components';
import {getISOWeek} from 'date-fns';
import {catchError, map} from 'rxjs/operators';
import {Pagination} from '../models';
import {NewsletterMockService} from './newsletter-mock.service';
import {
  CampaignPeriodStatisticsResponse,
  CampaignStatisticsResponse,
  NewsletterStatisticsResponse,
  RecipientListsTableModel,
} from '../models/newsletter.model';
import {RhNewsletterByIdResponse, RhNewsletterForm, RhNewsletterRequest} from '@app/newsletter-new/models/newsletter';
import {RhCampaignForm} from '@app/newsletter-new/models/campaign';
import {PeriodEnum} from '@app/shared/filters/models/newsletter-link-statistics';
import {USER_ROLES_CAMPAIGN} from '@app/newsletter-new/models/user-roles.enum';
import {RhTemplateFormRequest} from '@app/newsletter-new/models/template';
import {Template, TemplatesListType} from '@core/models/newsboard/template';
import {SimpleUser} from '@core/models/newsboard';

export const NewsletterIdLiteral = '{newsletterId}';

@Injectable()
export class NewsletterService {
  location: Location;

  constructor(
    location: Location,
    private http: ApiService,
    private endpoints: EndpointsService,
    private datePipe: DatePipe,
    private mock: NewsletterMockService
  ) {
    this.location = location;
  }

  static dataUrlToFile(dataUrl, fileName) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], fileName, {type: mime});
    return file;
  }

  static parseContentBody(body: NewsletterValueModel) {
    // tslint:disable-next-line:max-line-length
    const weekOfYear = getISOWeek(new Date());
    const googleAnalyticsUrl = `https://www.google-analytics.com/collect?v=1&tid=UA-32005-3-1&cid=555&aip=1&t=pageview&dp=newsletter_cw${weekOfYear}_${encodeURIComponent(body.title.trim().replace(/\s/g, '_'))}&dt=${encodeURIComponent(body.title.trim().replace(/\s/g, '_'))}&dh=www.roche.com`;

    if (body.whenToSend === 0) {
      delete body.timeZone;
      delete body.scheduleSentDate;
    }

    return {
      ...body,
      googleAnalyticsUrl,
      languageId: +body.languageId,
      content: JSON.stringify({
        sections: body.content.sections.map((section) => {
          return {
            ...section,
            newsCount: +section.newsCount,
            news: section.news.map((news) => {
              if (news.imageUrl) {
                return news;
              } else {
                return {...news, imageUrl: location.origin + '/assets/images/news-placeholder.png'};
              }
            }),
          };
        }),
      }),
    };
  }

  private keyFirstLetterToLower = (childObject) => {
    const tmp = Array.isArray(childObject) ? [] : typeof childObject === 'object' ? {} : null;
    for (const i of Object.keys(childObject)) {
      if (i) {
        tmp[i.charAt(0).toLowerCase() + i.slice(1)] = childObject[i];
        if (typeof childObject[i] === 'object' && childObject[i]) {
          tmp[i.charAt(0).toLowerCase() + i.slice(1)] = this.keyFirstLetterToLower(childObject[i]);
        }
      }
    }
    return tmp;
  };

  public parseInlineBody(form: any, sections: Array<Section>) {
    // tslint:disable-next-line:max-line-length
    const weekOfYear = getISOWeek(new Date());
    const googleAnalyticsUrl = `https://www.google-analytics.com/collect?v=1&tid=UA-32005-3-1&cid=555&aip=1&t=pageview&dp=newsletter_cw${weekOfYear}_${encodeURIComponent((form.title ? form.title : '').trim().replace(/\s/g, '_'))}&dt=${encodeURIComponent((form.title ? form.title : '').trim().replace(/\s/g, '_'))}&dh=www.roche.com`;
    if (form.whenToSend === 0) {
      delete form.timeZone;
      delete form.scheduleSentDate;
    }
    sections = sections.map<Section>((s) => {
      return {
        id: s.id,
        type: s.type,
        subsections: s.subsections.map<Subsection>((ss) => {
          return {
            id: ss.id,
            colspan: ss.colspan,
            blocks: ss.blocks.map<Block>((b) => {
              let markup = b.markup;
              switch (b.type) {
                case BlockTypes.TEXT: {
                  const pStyling = ` style='color: #544F4F; font-size: 12px; letter-spacing: 0; line-height: 16px; margin: 0px;'`;
                  // tslint:disable-next-line:max-line-length
                  const hStyling = ` style='color: #0B41CD; font-size: 14px; font-weight: bold; letter-spacing: 0.03px; line-height: 22px; margin-top: 0; margin-bottom: 12px;'`;
                  const liStyling = ` style='color: #544F4F; font-size: 12px; letter-spacing: 0; line-height: 16px; margin-bottom: 0px;'`;
                  // tslint:disable-next-line:max-line-length
                  const aStyling = ` style='color: #0B41CD !important; font-size: inherit; letter-spacing: inherit; text-decoration: none; line-height: inherit; margin-bottom: 0px;'`;
                  markup = markup
                    .replace(/<p\s*(style='(.*?)'\s*)*/gm, `<p `)
                    .replace(/<h3\s*(style='(.*?)'\s*)*/gm, `<h3 `)
                    .replace(/<li\s*(style='(.*?)'\s*)*/gm, `<li `)
                    .replace(/<a\s*(style='(.*?)'\s*)*/gm, `<a `)
                    .replace(/<p/gm, `<p ${pStyling}`)
                    .replace(/<h3/gm, `<h3 ${hStyling}`)
                    .replace(/<li/gm, `<li ${liStyling}`)
                    .replace(/<a/gm, `<a ${aStyling}`)
                    .replace(/"/gm, `'`);
                  break;
                }
                default: {
                  break;
                }
              }
              return {
                id: b.id,
                type: b.type,
                markup,
              };
            }),
          };
        }),
      };
    });

    const stringifiedContent = JSON.stringify({sections})
      .replace(/font-family: Noto Sans;/gm, ``)
      .replace(/font-family: &quot;Noto Sans&quot;;/gm, ``);

    return {
      ...form,
      googleAnalyticsUrl,
      content: stringifiedContent,
    };
  }

  public parseInlineBodyNew(form: any, sections: Array<NewSection>) {
    // tslint:disable-next-line:max-line-length
    const weekOfYear = getISOWeek(new Date());
    const googleAnalyticsUrl = `https://www.google-analytics.com/collect?v=1&tid=UA-32005-3-1&cid=555&aip=1&t=pageview&dp=newsletter_cw${weekOfYear}_${encodeURIComponent((form.title ? form.title : '').trim().replace(/\s/g, '_'))}&dt=${encodeURIComponent((form.title ? form.title : '').trim().replace(/\s/g, '_'))}&dh=www.roche.com`;
    // if (form.whenToSend === 0) {
    //     delete form.timeZone;
    //     delete form.scheduleSentDate;
    // }
    sections = sections.map<NewSection>((s) => {
      return {
        id: s.id,
        type: s.type,
        background: s.background,
        verticalSpace: s.verticalSpace,
        subsections: s.subsections.map<NewSubsection>((ss) => {
          return {
            id: ss.id,
            colspan: ss.colspan,
            width: ss.width,
            blocks: ss.blocks.map((b) => {
              let markup = b.markup;
              switch (b.type) {
                case BlockTypes.TEXT: {
                  const styles = {
                    'newsletter-typography--heading-1': `font-family: 'Noto Sans', 'Roboto', sans-serif; color: #21201f; font-size: 24px; font-style: normal; font-weight: 300; line-height: 36px; margin: 0;`,
                    'newsletter-typography--heading-2': `font-family: 'Noto Sans', 'Roboto', sans-serif; color: #21201f; font-size: 18px; font-style: normal; font-weight: 600; line-height: 27px;  letter-spacing: 0.2px; margin: 0;`,
                    'newsletter-typography--quotation-s': `font-family: 'Noto Serif', 'Roboto Serif', serif; color: #21201f; font-size: 14px; font-style: italic; font-weight: 400; line-height: 21px; letter-spacing: 0.2px; margin: 0;`,
                    'newsletter-typography--quotation-m': `font-family: 'Noto Serif', 'Roboto Serif', serif; color: #21201f; font-size: 16px; font-style: italic; font-weight: 400; line-height: 21px; letter-spacing: 0.2px; margin: 0;`,
                    'newsletter-typography--body-s': `font-family: 'Noto Sans', 'Roboto', sans-serif; color: #21201f; font-size: 12px; font-style: normal; font-weight: 400; line-height: 18px; letter-spacing: 0.2px; margin: 0;`,
                    'newsletter-typography--body-m': `font-family: 'Noto Sans', 'Roboto', sans-serif; color: #21201f; font-size: 14px; font-style: normal; font-weight: 400; line-height: 21px; letter-spacing: 0.2px; margin: 0;`,
                  }

                  markup = markup
                    .replace(
                      /class=(\\\"|\"|\')(?<class>.*?)(\\\"|\"|\')\s*(style=.*?" )*/gm,
                      (match, p1, p2, p3, p4) => {
                        return `class="${p2}"`

                      }
                    )
                    .replace(/<a\s*(style='(.*?)'\s*)*/gm, `<a `)
                    .replace(
                      /class=(\\\"|\"|\')(?<class>.*?)(\\\"|\"|\')/gm,
                      (match, p1, p2, p3, p4) => {
                        return `class="${p2}" style="${styles[`${p2}`]}" `
                      }
                    )
                    .replace(/<a/gm, `<a style="text-decoration: none;"`)
                    .replace(/<ul>(.*?\n.*?)<li>/gm, `<ul>$1<li style="color: #0b41cd;">`);

                  break;
                }
                default: {
                  break;
                }
              }
              return {
                id: b.id,
                type: b.type,
                markup,
                settings: null,
              };
            }),
          };
        }),
      };
    });

    const stringifiedContent = JSON.stringify({sections});
    // .replace(/font-family: Noto Sans;/gm, ``)
    // .replace(/font-family: &quot;Noto Sans&quot;;/gm, ``)
    // .replace(/font-family: Noto Serif;/gm, ``)
    // .replace(/font-family: &quot;Noto Serif&quot;;/gm, ``);

    return {
      ...form,
      googleAnalyticsUrl,
      content: stringifiedContent,
    };
  }

  parseInlineContentData(
    result: NewsletterLoadedValueModel,
    newsletterId
  ): {
    form: NewsletterInlineForm;
    sections: Array<Section>;
  } {
    const parsedContentToFormValue = {} as {
      sections: Array<Section>;
    };
    let contentString: string = result.content;
    contentString = contentString
      .replace(/src='(.*?)'/gm, (match, p1) => `src='${p1.split('?')[0]}?${Date.now()}'`)
      .replace(/class='email-block__image' src/gm, `class='email-block__image' crossorigin='anonymous' src`);
    const content: {
      sections: Array<Section>;
    } = JSON.parse(contentString);
    for (const i of Object.keys(content)) {
      if (typeof content[i] === 'object') {
        parsedContentToFormValue[i.charAt(0).toLowerCase() + i.slice(1)] = this.keyFirstLetterToLower(content[i]);
      }
    }

    let color = ColorsPalette.find((c) => c.label === result.color);
    if (!color) {
      color = ColorsPalette[0];
    }

    delete result.content;
    return {
      form: {
        ...result,
        newsletterId: +newsletterId,
        color: color.label,
        colorDark: color.valueDark,
        colorLight: color.valueLight,
      },
      sections: parsedContentToFormValue.sections,
    };
  }

  parseContentDataNew(
    result: RhNewsletterForm,
    newsletterId
  ): {
    form: RhNewsletterForm;
    sections: Array<Section>;
  } {
    const parsedContentToFormValue = {} as {
      sections: Array<Section>;
    };
    let contentString: string = result.content;
    contentString = contentString
      .replace(/src='(.*?)'/gm, (match, p1) => `src='${p1.split('?')[0]}?${Date.now()}'`)
      .replace(/class='email-block__image' src/gm, `class='email-block__image' crossorigin='anonymous' src`);

    const content: {
      sections: Array<Section>;
    } = JSON.parse(contentString);
    for (const i of Object.keys(content)) {
      if (typeof content[i] === 'object') {
        parsedContentToFormValue[i.charAt(0).toLowerCase() + i.slice(1)] = this.keyFirstLetterToLower(content[i]);
      }
    }

    let color = ColorsPalette.find((c) => c.label === result.color);
    if (!color) {
      color = ColorsPalette[0];
    }

    delete result.content;
    return {
      form: {
        ...result,
        newsletterId: +newsletterId,
        color: color.label,
        colorDark: color.valueDark,
        colorLight: color.valueLight,
      },
      sections: parsedContentToFormValue.sections,
    };
  }

  public parseContentData(result: NewsletterLoadedValueModel): NewsletterValueModel {
    const parsedContentToFormValue = {} as NewsletterSectionsModel;
    const contentString: string = result.content;
    const content: {
      sections: NewsletterSectionsModel[];
    } = JSON.parse(contentString);

    for (const i of Object.keys(content)) {
      if (typeof content[i] === 'object') {
        parsedContentToFormValue[i.charAt(0).toLowerCase() + i.slice(1)] = this.keyFirstLetterToLower(content[i]);
      }
    }
    let color = ColorsPalette.find((c) => c.label === result.color);
    if (!color) {
      color = ColorsPalette[0];
    }
    return {
      ...result,
      content: parsedContentToFormValue,
      color: color.label,
      colorDark: color.valueDark,
      colorLight: color.valueLight,
    };
  }

  public getTemplates(): Observable<TemplateModel[]> {
    const url = this.endpoints.ENDPOINT.TEMPLATE.GET;

    return this.http.get(this.endpoints.addParams(url, {})).pipe(map((res) => [...res]));
  }

  public getEmailFrom(): Observable<string> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_EMAIL;

    return this.http.get(this.endpoints.addParams(url, {}));
  }

  public getNewsletter(newsletterId: number): Observable<NewsletterLoadedValueModel> {
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.GET_NEWSLETTER_BY_ID, {
      id: newsletterId,
    });

    return this.http.get(this.endpoints.addParams(url, {})).pipe(
      map((newsletter) => ({
        ...newsletter,
        newsletterId,
      }))
    );
  }

  public getNewsletter2(newsletterId: number): Observable<RhNewsletterByIdResponse> {
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.GET_NEWSLETTER_BY_ID, {
      id: newsletterId,
    });

    return this.http.get(this.endpoints.addParams(url, {})).pipe(
      map((newsletter) => ({
        ...newsletter,
        newsletterId,
      }))
    );
  }

  public getNewsletters(): Observable<{
    months: {
      month: string;
      newsletters: NewsletterListItemModel[];
    }[];
  }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_NEWSLETTERS;

    return this.http.get(this.endpoints.addParams(url, {}));
  }

  public getMySavedTemplates(pageIndex: number, pageSize: number): Observable<MySavedTemplatesResponse> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL_TEMPLATES;

    return this.http.get(
      this.endpoints.addParams(url, {
        pageIndex,
        pageSize,
      })
    );
  }

  public getMySharedTemplates(pageIndex: number, pageSize: number): Observable<MySavedTemplatesResponse> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL_SHARED_TEMPLATES;

    return this.http.get(
      this.endpoints.addParams(url, {
        pageIndex,
        pageSize,
      })
    );
  }

  public createCampaign(campaign: RhCampaignForm): Observable<any> {
    const url = campaign.id
      ? this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.PUT.replace('{id}', '' + campaign.id)
      : this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.POST;

    if (!campaign.id) {
      delete campaign.id;
    }
    return this.http[campaign.id ? 'put' : 'post'](url, {
      ...campaign,
      campaignUserRoles: [
        ...campaign.permissions.owners.map((i) => {
          return {...i, userLogin: i.identifier, role: 1, firstName: i.firstName, lastName: i.lastName};
        }),
      ],
    });
  }

  public getCampaign(id: number): Observable<RhCampaignForm> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.GET.replace('{id}', '' + id);
    this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.GET, {id: id});
    return this.http.get(url).pipe(
      map((res) => {
        return {
          ...res,
          permissions: {
            owners: [
              ...res.campaignUserRoles
                .filter((i) => i.role === USER_ROLES_CAMPAIGN.OWNER)
                .map((i) => {
                  return {...i, identifier: i.userLogin, name: i.firstName + ' ' + i.lastName, role: 'owners'};
                }),
            ],
            editors: [
              ...res.campaignUserRoles
                .filter((i) => i.role === USER_ROLES_CAMPAIGN.EDITOR)
                .map((i) => {
                  return {...i, identifier: i.userLogin, name: i.firstName + ' ' + i.lastName, role: 'editors'};
                }),
            ],
          },
          recipientListIds: [...res.recipientLists.map((i) => i.id)],
          subscriptions: [...res.subscriptions
            .filter((s) => new Date(s.subscribedAt).getTime() > new Date(s.unsubscribedAt).getTime())
            .map((i) => ({
              date: new Date(i.subscribedAt),
              userId: i.userId,
              email: i.userEmail,
              name: i.userName,
            }))
          ],
          unsubscriptions: res.subscriptions
            .filter((s) => new Date(s.unsubscribedAt).getTime() > new Date(s.subscribedAt).getTime())
            .map((i) => ({
              date: new Date(i.subscribedAt),
              userId: i.userId,
              email: i.userEmail,
              name: i.userName,
            })),
        };
      })
    );
  }

  public getCampaigns({
                        pageIndex,
                        pageSize,
                        filters,
                        sort,
                      }: {
    pageSize: number;
    pageIndex: number;
    sort: {
      active: string;
      direction: SortDirection;
    };
    filters: {
      [key: string]: any;
    };
  }) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.GET_ALL;
    return this.http
      .post(
        this.endpoints.addParams(url, {
          pageIndex,
          pageSize,
          sort: sort?.active,
          order: sort?.direction,
        }),
        {...filters}
      )
      .pipe(
        map(
          (
            res
          ): {
            data: Array<any>;
            pagination: Pagination;
          } => {
            return {
              data: res.data,
              pagination: {
                isFirst: res.isFirst,
                isLast: res.isLast,
                pageCount: res.pageCount,
                pageIndex: res.pageIndex,
                pageSize: res.pageSize,
                totalCount: res.totalCount,
              },
            };
          }
        )
      );
    // return this.mock.getCampaigns(body);
  }

  public getAllCampaigns() {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.GET_ALL;
    return this.http.get(this.endpoints.addParams(url, {})).pipe(
      map(
        (
          res
        ): {
          data: Array<any>;
        } => {
          return {
            data: res,
          };
        }
      )
    );
    // return this.mock.getCampaigns(body);
  }

  public getAllRecipientsLists(): Observable<Array<RecipientListsTableModel>> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.PERSONALIZED;

    return this.http.get(this.endpoints.addParams(url, {}));
    // return this.mock.getAllRecipientLists(personalizedOnly);
  }

  public getRecipientLists({
                             pageIndex,
                             pageSize,
                             filters,
                             sort,
                           }: {
    pageSize: number;
    pageIndex: number;
    sort: {
      active: string;
      direction: SortDirection;
    };
    filters: {
      [key: string]: any;
    };
  }) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.TABLE;

    return this.http
      .post(
        this.endpoints.addParams(url, {
          pageIndex,
          pageSize,
          sort: sort.active,
          order: sort.direction,
        }),
        {...filters}
      )
      .pipe(
        map(
          (
            res
          ): {
            data: Array<any>;
            pagination: Pagination;
          } => {
            return {
              data: res.data,
              pagination: {
                isFirst: res.isFirst,
                isLast: res.isLast,
                pageCount: res.pageCount,
                pageIndex: res.pageIndex,
                pageSize: res.pageSize,
                totalCount: res.totalCount,
              },
            };
          }
        )
      );
  }

  public deleteList(ids: number[]): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.DELETE;

    return this.http.deleteWithBody(url, ids);
  }

  public deleteCampaign(id: number): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.DELETE.replace('{id}', '' + id);

    return this.http.deleteWithBody(url, {});
  }

  public archiveCampaign(id: number): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.ARCHIVE.replace('{id}', '' + id);

    return this.http.put(url, {});
  }

  public unArchiveCampaign(id: number): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.UNARCHIVE.replace('{id}', '' + id);

    return this.http.put(url, {});
  }

  public shareCampaign(id: number, permissions): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.SEND.replace('{id}', '' + id);

    return this.http.put(url, {
      campaignI: id,
      users: [
        ...permissions.owners.map((i) => {
          return {...i, userLogin: i.identifier, role: USER_ROLES_CAMPAIGN.OWNER};
        }),
        ...permissions.editors.map((i) => {
          return {...i, userLogin: i.identifier, role: USER_ROLES_CAMPAIGN.EDITOR};
        }),
      ],
    });
  }

  public getCampaignNewsletterInfo(id: number): Observable<Array<any>> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.GET_NEWSLETTER_LIST.replace('{id}', '' + id);

    return this.http.get(url);
  }

  public sendList(id: number, users): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.SEND.replace('{id}', '' + id);

    return this.http.post(url, users);
  }

  public getDeliveryStatisticsNewsletter(id): Observable<NewsletterStatisticsResponse> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.STATISTICS.GET_FOR_SINGLE.replace('{id}', '' + id);

    return this.http.get(url);
  }

  public getDeliveryStatisticsCampaign(id, range: { start: Date; end: Date }): Observable<CampaignStatisticsResponse> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.STATISTICS.GET_FOR_CAMPAIGN.replace('{id}', '' + id);
    return this.http.post(url, {
      startDate: range.start,
      endDate: range.end,
    });
  }

  public getPeriodStatisticsCampaign(
    id,
    grouping: PeriodEnum,
    date: Date
  ): Observable<Array<CampaignPeriodStatisticsResponse>> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.STATISTICS.POST_RANGE_FOR_CAMPAIGN.replace('{id}', '' + id);
    const newDate = `${this.datePipe.transform(date, 'YYYY-MM-ddThh:mm:ss.000')}Z`;
    const offset = new Date(date).getTimezoneOffset() * -1;
    return this.http.post(url, {
      grouping,
      offset,
      date: newDate,
    });
  }

  public getFeedbackStatistics(id): Observable<
    Array<{
      index: number;
      count: number;
    }>
  > {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.STATISTICS.GET_FEEDBACK.replace('{id}', '' + id);

    return this.http.get(url);
  }

  public getSurveyStatistics(id): Observable<
    Array<{
      index: number;
      count: number;
    }>
  > {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.STATISTICS.GET_SURVEY.replace('{id}', '' + id);

    return this.http.get(url);
  }

  public getSentToMeList() {
    return this.mock.getSentToMeList();
  }

  public acceptSentToMeList(id: number) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.ACCEPT.replace('{id}', '' + id);

    return this.http.put(url, true);
  }

  public rejectSentToMeList(id: number) {
    return this.deleteList([id]);
  }

  public getLinkStatistics(
    newsletterId,
    {
      pageIndex,
      pageSize,
      filters,
      sort,
    }: {
      pageSize: number;
      pageIndex: number;
      sort: {
        active: string;
        direction: SortDirection;
      };
      filters: {
        [key: string]: any;
      };
    }
  ) {
    return this.mock.getLinkStatistics(newsletterId, {pageIndex, pageSize, filters, sort});
  }

  public getAll({
                  sort,
                  pageIndex,
                  pageSize,
                  filters,
                }: {
    sort: Sort;
    pageSize: number;
    pageIndex: number;
    filters: {
      [key: string]: any;
    };
  }) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;
    const body = {
      search: filters.search?.trim(),
      statuses: filters.statuses,
      isContributed: filters.isContributed,
    };
    return this.http
      .post(this.endpoints.addParams(url, {pageSize, pageIndex, sort: sort.active, order: sort.direction}), body)
      .pipe(
        map(
          (
            res
          ): {
            data: Array<NewsletterTableModel>;
            pagination: Pagination;
          } => ({
            // data: res.data,
            data: res.data,
            pagination: {
              isFirst: res.isFirst,
              isLast: res.isLast,
              pageCount: res.pageCount,
              pageIndex: res.pageIndex,
              pageSize: res.pageSize,
              totalCount: res.totalCount,
            },
          })
        )
      );
  }

  public getAllNewslettersForCampaignId({
                                          sort,
                                          pageIndex,
                                          pageSize,
                                          filters,
                                          id,
                                        }: {
    sort: Sort;
    id?: number;
    pageSize: number;
    pageIndex: number;
    filters: {
      [key: string]: any;
    };
  }) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL_NEWSLETTER_FOR_CAMPAIGN.replace('{id}', '' + id);
    // const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;
    const body = {
      search: filters.search?.trim(),
      statuses: filters.statuses,
      isContributed: filters.isContributed,
    };
    return this.http
      .post(this.endpoints.addParams(url, {pageSize, pageIndex, sort: sort.active, order: sort.direction}), body)
      .pipe(
        map(
          (
            res
          ): {
            data: Array<NewsletterTableModel>;
            pagination: Pagination;
          } => ({
            // data: res.data,
            data: res.data,
            pagination: {
              isFirst: res.isFirst,
              isLast: res.isLast,
              pageCount: res.pageCount,
              pageIndex: res.pageIndex,
              pageSize: res.pageSize,
              totalCount: res.totalCount,
            },
          })
        )
      );
  }

  public getMyAllDraft(pageSize = 3, pageIndex = 0): Observable<NewsletterListModel> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;

    return this.http.get(this.endpoints.addParams(url, {pageSize, pageIndex, newsletterStatus: 0}));
  }

  public getMyAllSent(pageSize = 3, pageIndex = 0): Observable<NewsletterListModel> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;

    return this.http.get(this.endpoints.addParams(url, {pageSize, pageIndex, newsletterStatus: 1}));
  }

  public getMyAllScheduled(pageSize = 3, pageIndex = 0): Observable<NewsletterListModel> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;

    return this.http.get(this.endpoints.addParams(url, {pageSize, pageIndex, newsletterStatus: 2}));
  }

  public getSharedAllDraft(pageSize = 3, pageIndex = 0): Observable<NewsletterListModel> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;

    return this.http.get(
      this.endpoints.addParams(url, {pageSize, pageIndex, isContributed: true, newsletterStatus: 0})
    );
  }

  public getSharedAllSent(pageSize = 3, pageIndex = 0): Observable<NewsletterListModel> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;

    return this.http.get(
      this.endpoints.addParams(url, {pageSize, pageIndex, isContributed: true, newsletterStatus: 1})
    );
  }

  public getSharedAllScheduled(pageSize = 3, pageIndex = 0): Observable<NewsletterListModel> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_ALL;

    return this.http.get(
      this.endpoints.addParams(url, {pageSize, pageIndex, isContributed: true, newsletterStatus: 2})
    );
  }

  public getDraft(): Observable<DraftModel> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_DRAFT;

    return this.http.get(this.endpoints.addParams(url, {}));
  }

  public createDraft(body: NewsletterValueModel): Observable<{
    id: number;
  }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.POST_DRAFT;
    return this.http.post(this.endpoints.addParams(url, {}), NewsletterService.parseContentBody(body));
  }

  public updateDraft(body: NewsletterValueModel): Observable<{
    id: number;
  }> {
    const id = body.newsletterId;
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.PUT_DRAFT, {id});
    return this.http.put(this.endpoints.addParams(url, {}), NewsletterService.parseContentBody(body));
  }

  public publish(body: NewsletterValueModel): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_PUBLISH;
    return this.http.post(this.endpoints.addParams(url, {}), NewsletterService.parseContentBody(body));
  }

  public newPublish(form, sections): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_PUBLISH;
    const body = this.parseInlineBody(form, sections);
    return this.http.post(this.endpoints.addParams(url, {}), body);
  }

  public createDraftInline(form: NewsletterInlineForm, sections: Array<Section>): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.POST_DRAFT;
    const body = this.parseInlineBody(form, sections);

    return this.http.post(this.endpoints.addParams(url, {}), body);
  }

  public updateDraftInline(form: NewsletterInlineForm, sections: Array<Section>): Observable<any> {
    const id = form.newsletterId;
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.PUT_DRAFT, {id});
    const body = this.parseInlineBody(form, sections);

    return this.http.put(this.endpoints.addParams(url, {}), body);
  }

  public publishInline(form: NewsletterInlineForm, sections: Array<Section>): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_PUBLISH;
    const body = this.parseInlineBody(form, sections);
    return this.http.post(this.endpoints.addParams(url, {}), body);
  }

  public createDraftInlineNew(form: RhNewsletterRequest, sections: Array<NewSection>): Observable<any> {
    const id = form.newsletterId;
    const url = !id
      ? this.endpoints.ENDPOINT.NEWSLETTER.POST_DRAFT
      : this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.PUT_DRAFT, {id});
    const body = this.parseInlineBodyNew(form, sections);
    return this.http[!id ? 'post' : 'put'](this.endpoints.addParams(url, {}), body);
  }

  public createDraftInlineFromScheduled(form): Observable<any> {
    const id = form.newsletterId;
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.PUT_DRAFT, {id});
    let newsletter = {...form.newsletter, scheduleSentDate: null, timeZone: null};
    return this.http.put(this.endpoints.addParams(url, {}), newsletter);
  }

  public sendTestNew(form: RhNewsletterRequest, sections: Array<NewSection>): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SEND_TEST;
    return this.http.post(this.endpoints.addParams(url, {}), this.parseInlineBodyNew(form, sections));
  }

  public publishInlineNew(form: any, sections: Array<NewSection>): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_PUBLISH;
    const body = this.parseInlineBodyNew(form, sections);
    return this.http.post(this.endpoints.addParams(url, {}), body);
  }

  public publishInlineFromDraftNew(form: any, sections: Array<NewSection>): Observable<any> {
    const id = form.newsletterId;
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_PUBLISH_FROM_DRAFT, {id});
    const body = this.parseInlineBodyNew(form, sections);
    return this.http.put(this.endpoints.addParams(url, {}), body);
  }

  public publishInlineFromDraft(form: NewsletterInlineForm, sections: Array<Section>): Observable<any> {
    const id = form.newsletterId;
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_PUBLISH_FROM_DRAFT, {id});
    const body = this.parseInlineBody(form, sections);
    return this.http.put(this.endpoints.addParams(url, {}), body);
  }

  public publishNewsletterFromDraft(body: NewsletterValueModel): Observable<any> {
    const id = body.newsletterId;
    const url = this.endpoints.replaceUrlTokens(this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_PUBLISH_FROM_DRAFT, {id});

    return this.http.put(this.endpoints.addParams(url, {}), NewsletterService.parseContentBody(body));
  }

  public uploadFile(file: File): Observable<string> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.POST_PHOTO;
    return this.http.uploadFile(this.endpoints.addParams(url, {}), file);
  }

  public sendTest(body: NewsletterValueModel): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SEND_TEST;
    return this.http.post(this.endpoints.addParams(url, {}), NewsletterService.parseContentBody(body));
  }

  public sendTestInline(form: NewsletterInlineForm, sections: Array<Section>): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SEND_TEST;
    return this.http.post(this.endpoints.addParams(url, {}), this.parseInlineBody(form, sections));
  }

  public sendToPdf(body: NewsletterValueModel): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SEND_PDF;
    return this.http.put(this.endpoints.addParams(url, {}), NewsletterService.parseContentBody(body));
  }

  public getChannels(): Observable<ChannelNewsletterModel[]> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_CHANNELS;
    return this.http.get(this.endpoints.addParams(url, {}));
  }

  public getNewsForChannel(
    id: string,
    language: string
  ): Observable<{
    data: any[];
    totalCount: number;
  }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.GET_NEWS_FOR_CHANNEL;
    return this.http.get(this.endpoints.addParams(url, {channelId: id, pageSize: 250, language}));
  }

  public getUserAgreement(): Observable<boolean> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.AGREEMENT;

    return this.http.get(this.endpoints.addParams(url, {}));
  }

  public setUserAgreement(): Observable<void> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.AGREEMENT;

    return this.http.put(url, {});
  }

  public getUserOpenedNewsletter(): Observable<boolean> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.OPENED_NEWSLETTER;

    return this.http.get(this.endpoints.addParams(url, {}));
  }

  public setUserOpenedNewsletter(): Observable<void> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.OPENED_NEWSLETTER;

    return this.http.put(url, {});
  }

  public shareNewsletter(newsletterId, users): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SET_CONTRIBUTORS.replace('{newsletterId}', '' + newsletterId);

    return this.http.put(url, users);
  }

  public acceptSharedTemplate(data: { id: number | string }): Observable<void> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.ACCEPT_SHARED_TEMPLATE.replace(NewsletterIdLiteral, '' + data.id);
    return this.http.put(url, {newsletterId: data.id});
  }

  public getDynamicLink(id: string, title: string): Observable<NewsDynamicLink> {
    const weekOfYear = getISOWeek(new Date());
    const linkUrl =
      location.origin +
      '/content/' +
      id +
      '?title=' +
      title.trim().replace(/\s/g, '_').replace(/'/g, '%27') +
      `&utm_source=newsletter` +
      `&utm_medium=email` +
      `&utm_campaign=newsletter_cw${weekOfYear}_${title.trim().replace(/\s/g, '_').replace(/'/g, '%27')}`;
    const url = this.endpoints.addParams(this.endpoints.ENDPOINT.CONTENT.DYNAMIC_LINK, {
      url: encodeURIComponent(linkUrl),
    });

    return this.http.get(url).pipe(
      map((data) => ({
        id,
        linkUrl: data,
      }))
    );
  }

  public deleteNewsletter(id: number): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.DELETE.replace('{id}', '' + id);

    return this.http.delete(url);
  }

  public cancelNewsletter(id: number): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CANCEL.replace('{id}', '' + id);

    return this.http.put(url);
  }

  public saveAsTemplate(template: MySavedTemplatesModel): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.SAVE_AS_TEMPLATE.replace(
      NewsletterIdLiteral,
      '' + template.newsletterId
    );

    return this.http.post(url, template);
  }

  public getRecipientsList(id: number): Observable<Partial<RhRecipientsListRequestBody>> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.GET.replace('{id}', '' + id);

    return this.http.get(url);
  }

  public createRecipientsList(form: Partial<RhRecipientsListForm>): Observable<Partial<RhRecipientsListForm>> {
    const url = form.id
      ? this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.PUT.replace('{id}', '' + form.id)
      : this.endpoints.ENDPOINT.NEWSLETTER.RECIPIENT_LIST.POST;
    const newFormValue: RhRecipientsListRequestBody = {
      ...(form as RhRecipientsListRequestBody),
      recipients: [...form.recipientRada, ...form.recipientUsers],
      blockedRecipients: [...form.blockRada, ...form.blockUsers],
    };
    if (!form.id) {
      delete newFormValue.id;
    }
    return this.http[form.id ? 'put' : 'post'](url, newFormValue);
  }

  public getRada(email: string): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RADA_GROUPS.DETAILS.replace('{key}', '' + email);

    return this.http.get(url);
  }

  public getStatistics(
    id,
    {
      sort,
      pageIndex,
      pageSize,
    }: {
      sort: Sort;
      pageIndex: number;
      pageSize: number;
    }
  ): Observable<{
    data: Array<StatisticItemModel>;
    pagination: Pagination;
  }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.STATISTICS.LINK_LEGACY.replace('{id}', '' + id);
    const params = {
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    const body = {order: `${sort.active} ${sort.direction}`};
    return this.http.put(this.endpoints.addParams(url, params), body).pipe(
      map(
        (
          res: any
        ): {
          data: Array<StatisticItemModel>;
          pagination: Pagination;
        } => {
          return {
            data: res.data,
            pagination: {
              isFirst: res.isFirst,
              isLast: res.isLast,
              pageCount: res.pageCount,
              pageIndex: res.pageIndex,
              pageSize: res.pageSize,
              totalCount: res.totalCount,
            },
          };
        }
      )
    );
  }

  public rename(newsletterId, templateTitle): Observable<any> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RENAME.replace(NewsletterIdLiteral, '' + newsletterId);

    return this.http.put(url, {newsletterId: +newsletterId, templateTitle});
  }

  public searchRada(phrase: string) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.RADA_GROUPS.SEARCH;
    const urlWithParams = this.endpoints.addParams(url, {phrase});
    return this.http.get(urlWithParams).pipe(
      map((res) => {
        return {
          ...res,
          googleGroups: res.googleGroups ? res.googleGroups : [],
        };
      })
    );
  }

  getTemplateById(id: number): Observable<RhTemplateFormRequest> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.GET.replace('{id}', '' + id);
    // const urlWithParams = this.endpoints.addParams(url, {templateUpdate});
    return this.http.get(url);
  }

  updateTemplate(template: RhTemplateFormRequest, sections: Array<any>) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.UPDATE.replace('{id}', '' + template.id);
    // const urlWithParams = this.endpoints.addParams(url, {templateUpdate});
    return this.http.put(url, {...this.parseInlineBodyNew(template, sections)});
  }

  public createTemplate(template: RhTemplateFormRequest, sections: Array<any>) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.SEND;
    delete template.id;
    return this.http.post(url, this.parseInlineBodyNew(template, sections));
  }

  getTemplatesListAll({pageSize, pageIndex}: Pagination): Observable<{
    data: Array<Template>;
    pagination: Pagination;
  }> {
    const body = {
      pageSize: pageSize,
      pageIndex: pageIndex,
      view: null,
    };
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.FILTER;
    return this.http.post(url, body);
  }

  getTemplatesList(
    {pageSize, pageIndex}: Pagination,
    listType: TemplatesListType
  ): Observable<{
    data: Array<Template>;
    pagination: Pagination;
  }> {
    const body = {
      pageSize: pageSize,
      pageIndex: pageIndex
    };
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.FILTER;

    return this.http.post(this.endpoints.addParams(url, body), {view: listType}).pipe(map(response => {
      return {
        data: response.data,
        pagination: {
          isFirst: response.isFirst,
          isLast: response.isLast,
          pageCount: response.pageCount,
          pageIndex: response.pageIndex,
          pageSize: response.pageSize,
          totalCount: response.totalCount
        }
      };
    }));
  }

  deleteTemplate(id: number) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.DELETE.replace('{id}', '' + id);
    return this.http.delete(url);
  }

  public shareTemplate(data: { id: number | string; users: Array<SimpleUser> }): Observable<void> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.SHARE.replace('{id}', '' + data.id);
    return this.http.put(url, {users: data.users});
  }

  public sendTemplateCopy(data: { id: number | string; users: Array<SimpleUser> }): Observable<void> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.SEND_COPY.replace('{id}', '' + data.id);
    return this.http.put(url, {users: data.users.map((i) => ({...i, userLogin: i.identifier}))});
  }

  acceptTemplateCopy(id: number) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.ACCEPT.replace('{id}', '' + id);
    return this.http.put(url);
  }

  rejectTemplateCopy(id: number) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.REJECT.replace('{id}', '' + id);
    return this.http.put(url);
  }

  renameTemplate(id: number, title: string) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER_TEMPLATE.RENAME.replace('{id}', '' + id);
    return this.http.put(url, {templateTitle: title});
  }

  isSubscribed(campaignId: number) {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.IS_SUBSCRIBED.replace('{id}', '' + campaignId);
    return this.http.get(url);
  }

  getCampaignSubscriptions(): Observable<{ data: any }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.GET_SUBSCRIPTIONS;
    return this.http.get(url)
      .pipe(
        map((response) => ({
          data: response
        })),
        catchError((error: HttpError) => throwError('Cannot get user subscriptions'))
      )
  }

  subscribe(id: number): Observable<{ id: number, success: boolean }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.SUBSCRIBE.replace('{id}', '' + id);

    return this.http.put(url)
      .pipe(
        map((response) => ({
          id,
          success: response
        })),
        catchError((error: HttpError) => throwError('Cannot get user subscriptions'))
      )
  }

  unsubscribe(id: number): Observable<{ id: number, success: boolean }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER.CAMPAIGN.UNSUBSCRIBE.replace('{id}', '' + id);
    return this.http.put(url)
      .pipe(
        map((response) => ({
          id,
          success: response
        })),
        catchError((error: HttpError) => throwError('Cannot get user subscriptions'))
      )
  }

  checkCanEdit(type: 'RECIPIENT_LIST' | 'CAMPAIGN', id: number): Observable<{ id: number, data: boolean }> {
    const url = this.endpoints.ENDPOINT.NEWSLETTER[type]?.CAN_EDIT.replace('{id}', '' + id);
    return this.http.get(url)
      .pipe(
        map((data) => ({
          id,
          data,
        })),
        catchError((error: HttpError) => throwError('Cannot get user subscriptions'))
      )
  }

  private prepareSortParam(sort: { [key: string]: SortEnum }) {
    // tslint:disable-next-line
    if (sort) {
      for (const key of Object.keys(sort)) {
        if (sort[key] !== SortEnum.NONE) {
          return `${key} ${sort[key]}`.toLowerCase();
        }
      }
    }

    return 'none';
  }
}
