import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import * as fromBackButton from '@app/root-store/ui/back-button';
import * as fromActions from './newsletter-table.actions';
import * as fromRouter from '@app/root-store/router';
import * as fromReducer from './newsletter-table.reducer';
import * as fromSelectors from './newsletter-table.selectors';
import * as fromNewsletterForm from '../newsletter-form';
import * as fromNewsletterWebSocket from '../newsletter-websocket';
import * as fromTemplateForm from '@app/newsletter-new/store/template-form';
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of, tap} from 'rxjs';
import {RdsDialogService} from '@rds/angular-components';
import {NewsletterTableModel} from '@app/newsletter/models';
import {NewsletterService} from '@app/core/services/newsletter.service';
import {RenameDialogComponent, RenameDialogData} from '@app/shared/dialogs/rename-dialog/rename-dialog.component';
import {ConfirmDialogComponent} from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromUser from '@core/user/store';
import {
  SendCopyDialogComponent,
  SendCopyDialogData,
} from '@shared/dialogs/send-copy-dialog/send-copy-dialog.component';

export function compareNewsletterContributors(newVal, oldVal) {
  console.log(newVal, oldVal);
  let notChanged = newVal.filter((nv) => oldVal.find((ov) => ov.login === nv.login));
  let added = newVal.filter((nv) => !oldVal.find((ov) => ov.login === nv.login));
  let removed = oldVal.filter((ov) => !newVal.find((nv) => nv.login === ov.login));
  console.log(notChanged, added, removed);

  if (!oldVal.length) {
    notChanged = added.slice(0, 1);
    added = added.slice(1, added.length);
  }

  return {
    added,
    removed,
    notChanged,
  };
}

@Injectable()
export class NewsletterTableEffects {
	public initTableFromCampaign$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.campaignInit),
				switchMap(({ id }) =>
					this.store$.pipe(
						select(fromSelectors.selectTableRequestData),
						debounceTime(300),
						distinctUntilChanged((prev, next) => {
							if (prev.resetIndex && !next.resetIndex) {
								delete prev.resetIndex;
								delete next.resetIndex;
							}
							return JSON.stringify(prev) === JSON.stringify(next);
						}),
						map(({ filters, pageIndex, pageSize, sort, resetIndex }) => ({
							filters,
							sort,
							pageIndex: resetIndex ? 0 : pageIndex,
							pageSize,
							id
						}))
					)
				),
				map((requestData) => fromActions.table.campaignRequest(requestData))
			),
		{ dispatch: true }
	);

	public getTableFromCampaignRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.campaignRequest),
				switchMap((requestData) =>
					this.newsletterService.getAllNewslettersForCampaignId(requestData).pipe(
						map((res) => ({
							data: res.data as Array<NewsletterTableModel>,
							pagination: res.pagination,
						}))
					)
				),
				map(({ data, pagination }) => fromActions.table.success({ data, pagination, view: 'campaignNewsletters' })),
				catchError(({ message }) => of(fromActions.table.failure({ error: message })))
			),
		{ dispatch: true }
	);

  public initTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.table.init),
        switchMap(() =>
          this.store$.pipe(
            select(fromSelectors.selectTableRequestData),
            debounceTime(300),
            distinctUntilChanged((prev, next) => {

              if (prev.resetIndex && !next.resetIndex) {
                delete prev.resetIndex;
                delete next.resetIndex;
              }
              return JSON.stringify(prev) === JSON.stringify(next);
            }),
            map(({filters, pageIndex, pageSize, sort, resetIndex}) => ({
              filters,
              sort,
              pageIndex: resetIndex ? 0 : pageIndex,
              pageSize,
            })),
            map((requestData) => fromActions.table.request(requestData))
          )
        )
      ),
    {dispatch: true}
  );

  public getTableRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.table.request),
        switchMap((requestData) =>
          this.newsletterService.getAll(requestData).pipe(
            map((res) => ({
              data: res.data as Array<NewsletterTableModel>,
              pagination: res.pagination,
            }))
          )
        ),
        map(({data, pagination}) => fromActions.table.success({data, pagination})),
        catchError(({message}) => of(fromActions.table.failure({error: message})))
      ),
    {dispatch: true}
  );

  public connectWebSocketOnInitTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.table.init),
        withLatestFrom(this.store$.pipe(select(fromNewsletterWebSocket.selectIsWebSocketConnected))),
        filter(([action, isConnected]) => !isConnected),
        map(() => fromNewsletterWebSocket.wsConnect())
      ),
    {dispatch: true}
  );

  public updateWebSocketOnTableUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.table.success),
        withLatestFrom(
          this.store$.pipe(select(fromNewsletterWebSocket.selectIsWebSocketConnected)),
          this.store$.pipe(select(fromSelectors.selectTableDataContributedIds))
        ),
        distinctUntilChanged(),
        filter(([action, isConnected, newsletterIds]) => isConnected && newsletterIds.length > 0),
        map(() => fromNewsletterWebSocket.wsUpdateNewsletters())
      ),
    {dispatch: true}
  );

  public openNewsPreview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openNewsPreview),
        switchMap(({id, backButton}) => [
          fromRouter.go({path: `newsletter/newsletter/${id}/preview`, queryParams: {}}),
          fromBackButton.set(backButton),
        ])
      ),
    {dispatch: true}
  );

  public openDeleteNewsletterDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deleteNewsletter.dialog),
        map(({newsletter}) => {
          const dialog = this.dialogService.open(ConfirmDialogComponent, {
            data: {
              ids: [newsletter.id],
              title: `Are you sure you want to delete "${newsletter.displayName || newsletter.title}" newsletter draft?`,
              confirmButtonLabel: `Yes, delete`,
              confirmButtonType: 'warning',
            },
          });
          return {id: newsletter.id, dialog, newsletter};
        }),
        switchMap(({id, dialog, newsletter}) =>
          dialog.afterClosed().pipe(
            filter((data) => !!data),
            map((name) => fromActions.deleteNewsletter.request({id, newsletter}))
          )
        )
      ),
    {dispatch: true}
  );

  public deleteNewsletter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deleteNewsletter.request),
        switchMap(({id, newsletter}) =>
          this.newsletterService.deleteNewsletter(id).pipe(
            switchMap(() => [
              fromActions.deleteNewsletter.success(),
              fromNewsletterWebSocket.wsUpdateNewslettersWithNewsletterId({
                newsletterId: id,
                action: 'deleted',
                data: {...newsletter},
              }),
            ]),
            catchError(({message}) => of(fromActions.deleteNewsletter.failure({error: message})))
          )
        )
      ),
    {dispatch: true}
  );
  public openSendListCopyDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.shareNewsletter.dialog),
        withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
        map(([{newsletter}, userProfile]) => {
          const data: SendCopyDialogData = {
            title: 'Manage newsletter editors',
            confirmButtonLabel: 'Change editors',
            initialUsers: newsletter.newsletterContributors.length
              ? newsletter.newsletterContributors.map((i) => {
                return {
                  ...i,
                  identifier: i.login,
                  name: `${i.firstName} ${i.lastName}`,
                  email: i.email,
                  avatar: i.photoUrl,
                };
              })
              : [
                {
                  identifier: userProfile.login,
                  name: `${userProfile.firstName} ${userProfile.lastName}`,
                  email: userProfile.email,
                  avatar: userProfile.photoUrl,
                },
              ],
            notRemovable: [userProfile.login],
          };

          const dialog = this.dialogService.open(SendCopyDialogComponent, {
            size: 'l',
            data,
          });
          return {newsletter, dialog};
        }),
        switchMap(({newsletter, dialog}) =>
          dialog.afterClosed().pipe(
            filter((data) => !!data),
            map((newsletterContributors) => {
              return {newsletter, newsletterContributors};
            })
          )
        ),
        map(({newsletter, newsletterContributors}) =>
          fromActions.shareNewsletter.request({newsletter, newsletterContributors})
        )
      ),
    {dispatch: true}
  );

  public shareNewsletter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.shareNewsletter.request),

        switchMap(({newsletter, newsletterContributors}) => {
          console.log(newsletterContributors);
          return this.newsletterService
            .shareNewsletter(
              newsletter.id,
              newsletterContributors.map((i) => i['identifier'])
            )
            .pipe(
              switchMap((newsletterResponse) => [
                fromActions.shareNewsletter.success({
                  id: newsletter.id,
                  newsletterContributors: newsletterContributors.map((i) => ({
                    ...i,
                    login: i['identifier'],
                    photoUrl: i['avatar'],
                    name: i['name'] ? i['name'] : `${i.firstName} ${i.lastName}`,
                  })),
                }),
                fromNewsletterWebSocket.wsUpdateNewsletters(),
                fromNewsletterWebSocket.wsUpdateNewslettersWithNewsletterId({
                  newsletterId: newsletter.id,
                  action: 'shared',
                  data: {
                    ...newsletter,
                    newsletterContributors: compareNewsletterContributors(
                      newsletterContributors.map((i) => ({
                        ...i,
                        login: i['identifier'],
                        photoUrl: i['avatar'],
                        name: i['name'] ? i['name'] : `${i.firstName} ${i.lastName}`,
                      })),
                      newsletter.newsletterContributors
                    ),
                  },
                }),
              ]),
              catchError(({message}) => of(fromActions.shareNewsletter.failure({error: message})))
            );
        })
      ),
    {dispatch: true}
  );

  public openRenameNewsletterDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.renameNewsletter.dialog),
        map(({newsletter}) => {
          const data: RenameDialogData = {
            title: 'Rename Newsletter',
            oldName: newsletter.displayName || newsletter.title,
            controlLabel: 'Name of the Newsletter',
            confirmButtonLabel: 'Rename Newsletter',
            required: true,
            maxLength: 512,
          };
          const dialog = this.dialogService.open(RenameDialogComponent, {
            size: 'l',
            data,
          });
          return {id: newsletter.id, name: newsletter.title, dialog};
        }),
        switchMap(({id, name, dialog}) =>
          dialog.afterClosed().pipe(
            filter((data) => !!data),
            map((name) => fromActions.renameNewsletter.request({id, title: name}))
          )
        )
      ),
    {dispatch: true}
  );

  public renameNewsletter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.renameNewsletter.request),
        switchMap(({id, title}) =>
          this.newsletterService.rename(id, title).pipe(
            switchMap((newsletter) => [
              fromActions.renameNewsletter.success({
                newsletter: {
                  displayName: title,
                  newsletterId: id,
                },
              }),
              fromNewsletterWebSocket.wsUpdateNewslettersWithNewsletterId({
                newsletterId: id,
                action: 'renamed',
                data: {title},
              }),
            ]),
            catchError(({message}) => of(fromActions.renameNewsletter.failure({error: message})))
          )
        )
      ),
    {dispatch: true}
  );

  public openMoveToDraftDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.moveToDraft.dialog),
        map(({newsletter}) => {
          const dialog = this.dialogService.open(ConfirmDialogComponent, {
            data: {
              ids: [newsletter.id],
              title: `Are you sure you want to move to draft "${newsletter.displayName || newsletter.title}"?`,
              messages: ['The newsletter will not be sent.'],
              confirmButtonLabel: `Yes, move`,
              confirmButtonType: 'warning',
            },
          });
          return {newsletter, dialog};
        }),
        switchMap(({newsletter, dialog}) =>
          dialog.afterClosed().pipe(
            filter((data) => !!data),
            switchMap((name) =>
              this.newsletterService.getNewsletter2(newsletter.id).pipe(
                map((newsletter) => fromActions.moveToDraft.request({newsletter})),
                catchError(({message}) => of(fromActions.deleteNewsletter.failure({error: message})))
              )
            )
          )
        )
      ),
    {dispatch: true}
  );

  public moveToDraft$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.moveToDraft.request),

        switchMap(({newsletter}) =>
          this.newsletterService.createDraftInlineFromScheduled(newsletter).pipe(
            switchMap(() => [
              fromActions.moveToDraft.success({newsletter}),
              fromNewsletterWebSocket.wsUpdateNewslettersWithNewsletterId({
                newsletterId: newsletter.newsletterId,
                action: 'movedToDraft',
                data: {status: 0},
              }),
            ]),
            catchError(({message}) => of(fromActions.moveToDraft.failure({error: message})))
          )
        )
      ),
    {dispatch: true}
  );

  //   map(([action, user]): Partial<TemplateForm> => {
  //   const template = cloneDeep(EMPTY_TEMPLATE_FORM);
  //   return {
  // ...template,
  //   permissions: {
  //     ...template.permissions,
  //     owners: [
  //       {
  //         identifier: user.login,
  //         firstName: user.firstName,
  //         lastName: user.lastName,
  //         role: 'owners',
  //         order: 0,
  //       },
  //     ],
  //   },
  // };
  // }),
  public saveAsTemplate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.saveAsTemplate.request),
        withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
        switchMap(([{id}, user]) =>
          this.newsletterService.getNewsletter2(id).pipe(
            switchMap((newsletter) => {
              if (
                newsletter.templateId === 'NewsletterTemplateInlineEditor' ||
                newsletter.templateId === 'NewsletterTemplateWithSections' ||
                newsletter.templateId === 'NewsletterTemplateWithoutSections'
              ) {
                return [
                  fromRouter.go({
                    path: 'newsletter/newsletter/' + newsletter.newsletterId + '/preview',
                    queryParams: {},
                  }),
                ];
              }
              const template = {
                ...newsletter.newsletter,
                hasFeedback: newsletter.newsletter?.hasFeedback,
                hasSenderDisclaimer: newsletter.newsletter?.hasSenderDisclaimer,
                disclaimer: newsletter.newsletter?.disclaimer,
                hasImage: newsletter.newsletter?.hasImage,
                hasBanner: newsletter.newsletter?.hasBanner,
                image: newsletter.newsletter?.image,
                banner: newsletter.newsletter?.banner,
                sections: newsletter.newsletter.sections,
                content: newsletter.newsletter.content,
                newsletterTemplateUserRole: [],
                newsletterTemplateFeedbackSection: newsletter.newsletter.feedbackSettings,
                isPredefined: false,
                imagePreviewUrl: null,
                subject: newsletter.newsletter.subject,
                previewText: newsletter.newsletter.previewText,
                permissions: {
                  editors: [],
                  viewers: [],
                  owners: [
                    {
                      identifier: user.login,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      role: 'owners',
                      order: 0,
                    },
                  ],
                },
              };
              return [
                fromNewsletterForm.preparing.start({
                  useFor: 'edit',
                  newsletterResponse: newsletter,
                  shouldFetchSettingsData: true,
                }),

                fromTemplateForm.form.setFormValue({form: {...template, id: null}}),
                fromTemplateForm.save.dialog(),
              ];
            }),
            catchError(({message}) => of(fromActions.deleteNewsletter.failure({error: message})))
          )
        )
      ),
    {dispatch: true}
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromReducer.State>,
    private newsletterService: NewsletterService,
    private dialogService: RdsDialogService
  ) {
  }
}
